<template>
  <div class="notifications-setting">
    <h2 v-if="checkPath">お知らせ</h2>
    <div class="wrap">
      <div class="wrap-content">
        <div class="setting-tab-title-bar" v-if="checkPath">
          <div v-for="titleObj in tabTitles" :key="titleObj.id">
            <base-setting-tab-title v-bind="titleObj" />
          </div>
        </div>
        <div class="content-body">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'NotificationsSetting',
  components: {
    BaseSettingTabTitle
  },
  computed: {
    ...mapGetters({
      noticesNoReadCount: 'notices/getCount',
      receivedMailsNoReadCount: 'receivedMails/getCount',
      reservationRequestsCount: 'reservationRequests/getCount',
      cancelReservationsNoReadCount: 'reservations/getCount'
    }),
    checkPath() {
      if (this.$route.params.id) {
        return false
      }
      return true
    },
    tabTitles() {
      return [
        {
          id: 1,
          title: '通知',
          link: 'notices',
          names: ['notices'],
          noreadCount:
            this.noticesNoReadCount < 100 ? this.noticesNoReadCount : '99+'
        },
        {
          id: 2,
          title: '受信メール',
          link: 'received-mails',
          names: ['received-mails'],
          noreadCount:
            this.receivedMailsNoReadCount < 100
              ? this.receivedMailsNoReadCount
              : '99+'
        },
        {
          id: 3,
          title: '送信メール',
          link: 'send-mails',
          names: ['send-mails']
        },
        {
          id: 4,
          title: '予約申請',
          link: 'reservation-requests',
          names: ['reservation-requests'],
          reservationRequestsCount:
            this.reservationRequestsCount < 100
              ? this.reservationRequestsCount
              : '99+'
        },
        {
          id: 5,
          title: '予約キャンセル履歴',
          link: 'reservation-cancels',
          names: ['reservation-cancels'],
          noreadCount:
            this.cancelReservationsNoReadCount < 100
              ? this.cancelReservationsNoReadCount
              : '99+'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications-setting {
  width: 100%;
  text-align: left;
  padding: 24px 48px;
  overflow-y: auto;
  > h2 {
    font-size: 20px;
    margin: 0;
  }
  > .wrap {
    box-sizing: border-box;
    display: inline-block;
    min-width: 100%;
    > .wrap-content {
      margin-top: 20px;
      width: 100%;
      > .setting-tab-title-bar {
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 26px;
        border-bottom: 1px solid;
        border-color: #{$light-grey};
        padding-bottom: -4px;
        box-sizing: border-box;
      }
      > .content-body {
        margin-top: 20px;
        min-width: 870px;
      }
    }
  }
}
</style>
